import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import { sessionAtom } from '../../atoms/sessionAtom';
import loginActions from '../../actions/loginActions';
import './header.css';

const DashboardHeader = () => {
  const [user] = useRecoilState(userAtom);
  const [session, setSession] = useRecoilState(sessionAtom);

  const exit = () => {
    const newSession = {...session};
    newSession.requireValidation = false;
    newSession.logoff = true;
    setSession(newSession);
    loginActions.quit();
  };

  return (
    <nav className="guardian-header">
      <div className="container-fluid">
        <div>
          <div className="guardian-header-right">
            <button className="link-button forcedLink guardian-header-btn" >
              <i className="material-icons">person</i>
              <span>{user.userName}</span>
            </button>
            <button className="link-button forcedLink guardian-header-btn" onClick={exit}>
              <i className="material-icons">exit_to_app</i>
              <span>Sair</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default DashboardHeader;
