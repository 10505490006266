import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <nav className="pull-left">
          <ul>
            <li>
              Guardian
            </li>
          </ul>
        </nav>
        <p className="copyright pull-right">
          &copy;
          {new Date().getFullYear()}
          {/* <a href="http://www.jlxconsultoria.com.br">Guardian</a>, desenvolvido por profissionais de RH para profissionais de RH */}
          Guardian, desenvolvido por profissionais de RH para profissionais de RH
        </p>
      </div>
    </footer>
  );
};

export default Footer;
