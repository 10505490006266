import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import messages from '../../commons/constMessages';
import endpointServices from '../../services/clientServices';
import { useRecoilState } from 'recoil';
import { sessionAtom } from '../../atoms/sessionAtom';
import '../../assets/css/login.css?v=1.0.0';
import './login.css';

const LoginGuardian = props => {
  const recaptchaRef = React.createRef();
  const [session, setSession] = useRecoilState(sessionAtom);

  useEffect(() => {
    if (props.errorMessage) {
      const newSession = { ...session };
      newSession.requireValidation = true;
      setSession(newSession);
    }
  }, [props.errorMessage]);

  const handleSubmit = e => {
    e.preventDefault();
    if (props.isLoading) {
      return;
    }

    if (!endpointServices.persistEndpoint(props.user)) {
      props.showErrorMessage(messages.ErrMultiplasSecoes);
      return;
    }

    if (session.requireValidation) {
      const token = recaptchaRef.current.getValue();
      if (!token) {
        props.showErrorMessage(messages.ErrCaptcha);
        return;
      }
      recaptchaRef.current.reset();
    }
    props.proceedLogin();
  };

  const handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const localUser = { ...props.user };
    localUser[name] = value;

    props.setUser(localUser);
  };

  const getComponent = () => {
    let captcha = null;
    if (session.requireValidation) {
      captcha = <ReCAPTCHA sitekey="6LeYSKQjAAAAAGkM4x3ebeiZbiQURVicLrAq2qgk" ref={recaptchaRef} />;
    }

    const component =
      <>
        <input type="text" className="form-control" name="userName" value={props.user.userName} onChange={handleChange} placeholder="Usuário" required autoFocus />
        <input type="password" className="form-control" name="password" value={props.user.password} onChange={handleChange} placeholder="Senha" required />
        {captcha}
        {props.children}
        <button className="btn btn-lg btn-success btn-block" onClick={handleSubmit}>
          Acessar
        </button>
      </>;
    return component;
  };

  return (getComponent());
};

LoginGuardian.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  proceedLogin: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default LoginGuardian;
