import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import AsyncComponent from './components/AsyncComponent';
import App from './components/App';

const TabelaSalarial = () => import('./components/tabelaSalarial/TabelaSalarialPage');
const TabelaSalarialManager = () => import('./components/tabelaSalarial/TabelaSalarialManager');
const Secao = () => import('./components/secao/SecaoPage');
const SecaoManager = () => import('./components/secao/SecaoManager');
const ChefeSupervisor = () => import('./components/chefeSupervisor/ChefeSupervisorPage');
const ChefeSupervisorManager = () => import('./components/chefeSupervisor/ChefeSupervisorManager');
const VinculacaoTabSalarial = () => import('./components/vinculacaoTabSalarial/VinculacaoTabSalarialPage');
const VinculacaoTabSalarialManager = () => import('./components/vinculacaoTabSalarial/VinculacaoTabSalarialManager');
const Funcionario = () => import('./components/funcionario/FuncionarioPage');
const FuncionarioManager = () => import('./components/funcionario/FuncionarioManager');
const Demissao = () => import('./components/demissao/DemissaoPage');
const DemissaoManager = () => import('./components/demissao/DemissaoManager');
const Rescisao = () => import('./components/rescisao/RescisaoPage');
const RescisaoManager = () => import('./components/rescisao/RescisaoManager');
const QuadroLotacao = () => import('./components/quadroLotacao/QuadroLotacaoPage');
const QuadroLotacaoManager = () => import('./components/quadroLotacao/QuadroLotacaoManager');
const Filial = () => import('./components/filial/FilialPage');
const FilialManager = () => import('./components/filial/FilialManager');
const Departamento = () => import('./components/departamento/DepartamentoPage');
const DepartamentoManager = () => import('./components/departamento/DepartamentoManager');
const MovimentoIntegracao = () => import('./components/movimentoIntegracao/MovimentoIntegracaoPage');
const MovimentoIntegracaoManager = () => import('./components/movimentoIntegracao/MovimentoIntegracaoManager');
const Metadados = () => import('./components/metadados/MetadadosPage');
const MetadadosManager = () => import('./components/metadados/MetadadosManager');
const EventosProgramados = () => import('./components/eventosProgramados/EventosProgramadosPage');
const EventosProgramadosManager = () => import('./components/eventosProgramados/EventosProgramadosManager');
const Encargo = () => import('./components/encargo/EncargoPage');
const EncargoManager = () => import('./components/encargo/EncargoManager');
const Dependente = () => import('./components/dependente/DependentePage');
const DependenteManager = () => import('./components/dependente/DependenteManager');
const MovimentacaoEvento = () => import('./components/movimentacaoDeEvento/MovimentacaoEventoPage');
const MovimentacaoEventoManager = () => import('./components/movimentacaoDeEvento/MovimentacaoEventoManager');
const SubMenuFunc = () => import('./components/menu/SubMenuFunc');
const AlteraCodSecao = () => import('./components/alteraCodSecao/AlteraCodSecaoPage');
const AlteraCodSecaoManager = () => import('./components/alteraCodSecao/AlteraCodSecaoManager');
const TrocaChapa = () => import('./components/trocaChapa/TrocaChapaPage');
const TrocaChapaManager = () => import('./components/trocaChapa/TrocaChapaManager');
const Generic = () => import('./components/generic/GenericPage');
const GenericManager = () => import('./components/generic/GenericManager');

const AutRoutes = () => (
  <HashRouter>
    <Suspense fallback={<div>Carregando...</div>}>
      <App>
        <Routes>
          <Route exact path='/tabelasalarial' element={<AsyncComponent importComponent={TabelaSalarial} />}/>
          <Route exact path='/tabelasalarial/:id' element={<AsyncComponent importComponent={TabelaSalarialManager} />}/>
          <Route exact path='/secao' element={<AsyncComponent importComponent={Secao} />}/>
          <Route exact path='/secao/:id' element={<AsyncComponent importComponent={SecaoManager} />}/>
          <Route exact path='/chefesupervisor' element={<AsyncComponent importComponent={ChefeSupervisor} />}/>
          <Route exact path='/chefesupervisor/:id' element={<AsyncComponent importComponent={ChefeSupervisorManager} />}/>
          <Route exact path='/vinculacaotabsalarial' element={<AsyncComponent importComponent={VinculacaoTabSalarial} />}/>
          <Route exact path='/vinculacaotabsalarial/:id' element={<AsyncComponent importComponent={VinculacaoTabSalarialManager} />}/>
          <Route exact path='/funcionario' element={<AsyncComponent importComponent={Funcionario} />}/>
          <Route exact path='/funcionario/:id' element={<AsyncComponent importComponent={FuncionarioManager} />}/>
          <Route exact path='/demissao' element={<AsyncComponent importComponent={Demissao} />}/>
          <Route exact path='/demissao/:id' element={<AsyncComponent importComponent={DemissaoManager} />}/>
          <Route exact path='/rescisao' element={<AsyncComponent importComponent={Rescisao} />}/>
          <Route exact path='/rescisao/:id' element={<AsyncComponent importComponent={RescisaoManager} />}/>
          <Route exact path='/quadroLotacao' element={<AsyncComponent importComponent={QuadroLotacao} />}/>
          <Route exact path='/quadroLotacao/:id' element={<AsyncComponent importComponent={QuadroLotacaoManager} />}/>
          <Route exact path='/filial' element={<AsyncComponent importComponent={Filial} />}/>
          <Route exact path='/filial/:id' element={<AsyncComponent importComponent={FilialManager} />}/>
          <Route exact path='/departamento' element={<AsyncComponent importComponent={Departamento} />}/>
          <Route exact path='/departamento/:id' element={<AsyncComponent importComponent={DepartamentoManager} />}/>
          <Route exact path='/movimentoIntegracao' element={<AsyncComponent importComponent={MovimentoIntegracao} />}/>
          <Route exact path='/movimentoIntegracao/:id' element={<AsyncComponent importComponent={MovimentoIntegracaoManager} />}/>
          <Route exact path='/metadados' element={<AsyncComponent importComponent={Metadados} />}/>
          <Route exact path='/metadados/:id' element={<AsyncComponent importComponent={MetadadosManager} />}/>
          <Route exact path='/eventosProgramados' element={<AsyncComponent importComponent={EventosProgramados} />}/>
          <Route exact path='/eventosProgramados/:id' element={<AsyncComponent importComponent={EventosProgramadosManager} />}/>
          <Route exact path='/encargo' element={<AsyncComponent importComponent={Encargo} />}/>
          <Route exact path='/encargo/:id' element={<AsyncComponent importComponent={EncargoManager} />}/>
          <Route exact path='/dependente' element={<AsyncComponent importComponent={Dependente} />}/>
          <Route exact path='/dependente/:id' element={<AsyncComponent importComponent={DependenteManager} />}/>
          <Route exact path='/movimentacaoEvento' element={<AsyncComponent importComponent={MovimentacaoEvento} />}/>
          <Route exact path='/movimentacaoEvento/:id' element={<AsyncComponent importComponent={MovimentacaoEventoManager} />}/>
          <Route exact path='/subMenuFunc' element={<AsyncComponent importComponent={SubMenuFunc} />}/>
          <Route exact path='/alteraCodSecao' element={<AsyncComponent importComponent={AlteraCodSecao} />}/>
          <Route exact path='/alteraCodSecao/:id' element={<AsyncComponent importComponent={AlteraCodSecaoManager} />}/>
          <Route exact path='/trocaChapa' element={<AsyncComponent importComponent={TrocaChapa} />}/>
          <Route exact path='/trocaChapa/:id' element={<AsyncComponent importComponent={TrocaChapaManager} />}/>
          <Route exact path='/generic/:target' element={<AsyncComponent importComponent={Generic} />}/>
          <Route exact path='/generic/:target/:id' element={<AsyncComponent importComponent={GenericManager} />}/>
          <Route path='*' element={<Navigate to='/movimentoIntegracao' />} />
        </Routes>
      </App>
    </Suspense>
  </HashRouter>
);

export default AutRoutes;
