import React, { useState, useEffect } from 'react';
import MenuItem from './MenuItem';
import service from '../../api/services/menuServices';
import { PulseLoader } from 'react-spinners';
import './menu.css';

const MenuWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [reloadMenu, setReloadMenu] = useState(true);
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    if (loadMenu) {
      loadMenu();
      setReloadMenu(false);
    }
  }, [reloadMenu]);

  const loadMenu = () => {
    service.get().then(res => {
      if (res && res.data && res.data.data) {
        buildMenu(res.data.data);        
      }
    });
  };

  const buildMenu = items => {
    setIsLoading(true);
    const menuUpdated = [];    
    items.forEach(item => {
      menuUpdated.push(<MenuItem key={item.id} item={item} openChildren={() => openChildren(item)} />);
    });
    setItemList(menuUpdated);
    setIsLoading(false);
  };

  const openChildren = item => {
    if (!item.childMenu || !item.childMenu.length) {
      setReloadMenu(true);
      return;
    }
    const back = { id: 999, menuName: 'Voltar', menuIcon: 'arrow_back'};
    const menu = [...item.childMenu];
    menu.unshift(back);
    buildMenu(menu);
  };

  const getContent = () => {
    if (isLoading) {
      return <PulseLoader size={10} color={'#333'} loading={isLoading} />;
    }
    return itemList;
  };

  return (
    <div className="sidebar-wrapper">
      <ul className="nav nav-menu">
        {getContent()}
      </ul>
    </div>
  );
};

export default MenuWrapper;
