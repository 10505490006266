import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PublicClientApplication } from '@azure/msal-browser';
import '../../assets/css/login.css?v=1.0.0';
import './login.css';

const LoginAzure = props => {
  let publicClientApplication = null;

  useEffect(() => {
    if (props.user.client.azureAd && !props.user.authError) {
      props.setIsLoading(true);
      initiateAdAzure(props.user.client.azureAd);
    }
  }, [props.user.client.azureAd]);

  const loginAdAzure = async (scope) => {
    try {
      await publicClientApplication.loginRedirect({
        scopes: [scope],
        prompt: 'select_account'
      });
    }
    // eslint-disable-next-line no-empty
    catch (err) { }
  };

  const initiateAdAzure = adAzure => {
    publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: adAzure.appId,
        redirectUri: adAzure.redirectUri,
        authority: adAzure.authority
      },
      cache: {
        cacheLocation: 'sessionStorage'
      }
    });

    publicClientApplication.handleRedirectPromise()
      .then(s => {
        if (s !== null && s.account != null) {
          const user = { ...props.user };
          user.password = s.accessToken;
          user.userName = s.account.username;
          props.setUser(user);
        }
      })
      .catch(() => {
        props.showErrorMessage('Erro de autenticação, tente novamente mais tarde ou procure o administrador.');
      });

    loginAdAzure(adAzure.scope);
  };

  const reload = () => {
    const localUser = {...props.user};
    localUser.authError = false;
    props.setUser(localUser);
    window.location.reload();
  };

  const getComponent = () => {
    const component =
      <>
        {props.children}
        <button className="btn btn-lg btn-success btn-block" onClick={reload}>
          Recarregar
        </button>
      </>;
    return component;
  };

  return (getComponent());
};

LoginAzure.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  proceedLogin: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default LoginAzure;
