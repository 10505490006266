import React from 'react';
import PropTypes from 'prop-types';
import Menu from './menu/Menu';
import Header from './common/Header';
import Footer from './common/Footer';

const App = props => {
  return (
    <div className="wrapper">
      <Menu />
      <div className="main-panel">
        <Header />
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.object.isRequired
};

export default App;
