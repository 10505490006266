import loginApi from '../api/services/loginServices';
import { userAtom, defaultUser } from '../atoms/userAtom';
import { setRecoil } from 'recoil-nexus';

const actions = {};

actions.login = user => {
  return loginApi.login(user.userName, user.password, user.client.id);
};

actions.quit = () => {
  setRecoil(userAtom, { ...defaultUser });
};

export default actions;