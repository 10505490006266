import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './menu.css';

const MenuItem = ({ item, openChildren }) => {
  let component;
  if (item.menuLink) {
    component = 
    <NavLink to={`/${item.menuLink}`} className={({ isActive }) => (isActive ? 'guardian-menu-anchor selectedMenu' : 'guardian-menu-anchor unselectedMenu')}>
      <i className="material-icons guardian-menu-icon" >{item.menuIcon}</i>
      <span>{item.menuName}</span>
    </NavLink>;
  } else {
    component = 
    <a id="agrupador" className="guardian-menu-anchor unselectedMenu" onClick={openChildren}  >
      <i className="material-icons guardian-menu-icon" >{item.menuIcon}</i>
      <span>{item.menuName}</span>
    </a>;
  }

  return (
    <li id="guardian-menu-item" key={item.id}  >
      {component}
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  openChildren: PropTypes.func,
};

export default MenuItem;
