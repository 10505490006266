import React from 'react';
import MenuLogo from './MenuLogo';
import MenuWrapper from './MenuWrapper';

const Menu = props => {
  return (
    <div className="sidebar" >
      <MenuLogo />
      <MenuWrapper />
    </div>
  );
};

export default Menu;